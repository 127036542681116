function triggerVideo(triggerEl) {
  const $this = $(triggerEl);
  const videoEmbed = $this.siblings("iframe");

  $this.hide();
  videoEmbed.show();
  videoEmbed.each(function () {
    this.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', "*");
  });
  videoEmbed.get(0).scrollIntoView();
}

$(".video__cover-image").on("click", function () {
  triggerVideo(this);
});

// Homepage specific - different markup
$(".homepage__hero-image-wrapper--has-video").on("click", function () {
  triggerVideo(this);

  $(this).parent().css("align-items", "initial");
});