$("#readMoreTrigger").on("click", function () {
  var $this = $(this);
  var body = $("#readMoreBody");

  if ($this.attr("aria-expanded", "false")) {
    body.slideDown("slow");
  }

  $this.hide();
});
